// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400');

$primary: #1060AB; /* MAIN COLOR */
$secondary: #66ED6B; /* SECONDARY COLOR */
$blk: #000; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
.body {
	font-family: 'Josefin Sans', sans-serif;
}
.flash {
	display:none;
}
nav {
	z-index: 1000;
}
.logo {
	@media(max-width: 767px){
		max-height: 70px;
	}
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $blk;
	    	color: lighten($blk, 50%);
	    }
	}
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

p {
	font-family: 'Josefin Sans', sans-serif;

}
/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $blk;
	border-radius: $border-radius;
	background: $blk;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($blk,20%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $blk;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($blk,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $blk;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
/** END LOGIN FORM **/

// start section
section{
	padding: 50px 0;
}
.hero-banner {
	background: url('../img/bg1.jpg') no-repeat center/cover;
	padding: 350px 0;
	@media (max-width: 767px){
		padding: 50px 0;
	}
	h1 {
		font-size: 2.5em;
		font-weight: bold;
		color: $wht;
		@media (max-width: 767px){
			font-size: 1.5em;
		}
	}

	p {
		font-weight: 400;
		font-size: 1.7em;
	}
	.cta-text {
		margin-top: 10%;
	}
}
.cta-box {
	background: -moz-linear-gradient(left,  #1060ab 0%, #66ed6b 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #1060ab 0%,#66ed6b 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #1060ab 0%,#66ed6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1060ab', endColorstr='#66ed6b',GradientType=1 ); /* IE6-9 */
	h2 {
		font-size: 2em;
		color: $wht;
		text-transform: uppercase;
		@media (max-width: 767px){
			font-size: 1.5em;
		}

	}
	.text-italic {
		font-weight: bold;
		font-style: italic;
		font-size: 4em;
		color: transparent;
		text-shadow: $wht 0px 0px 1px;
		}
	p {
		color: $wht;
		font-size: 1.5em;
		font-weight: 200;
	}
}
.trio-section {
	background: #000000; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #000000 , #434343); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #000000 , #434343); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	padding: 60px 0;
	h1 {
		font-size: 2.5em;
		font-weight: bold;
		color: $wht;
		padding-bottom: 50px;
		text-transform: uppercase;letter-spacing: .25em;
		@media (max-width: 767px){
			font-size: 1.5em;
		}
	}
	h2 {
		font-size: 1.8em;
		font-style: italic;
		color: lighten($wht, 25%);
	}
	img {
		max-width: 250px;
	}
	p {
		font-size: 1.2em;
		color: $wht;
	}
}
.box {
	background: url('../img/bg2.jpg') no-repeat top/cover;
	padding: 200px 0;
	@media (max-width: 767px){
		padding: 60px 0;
	}
	h1 {
		font-size: 2.5em;
		color: $wht;
		text-transform: uppercase;
		letter-spacing: .25em;
		@media (max-width: 767px){
		font-size: 1.5em;
		}
	}
	p {
		font-size: 2em;
		color: $wht;
		@media (max-width: 767px){
		font-size: 1.5em;
		}
	}
}
// ends sections
// cta starts
.cta {
	padding: 20px 40px;
	background: transparent;
	color: $wht;
	font-size: 1.2em;
	margin: 5px;
	border-radius: 0px;
	border: 1px solid $wht;
	@media(max-width:767px){
			padding: 10px 20px;
			font-size: 1.2em;
	}
	&:hover{
		border-radius:0px;
		color: $wht;
		background:darken($blk, 45%);
		transition: all 0.7s ease 0s;
	}
}

.form {
	background: #8e9eab; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #8e9eab , #eef2f3); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #8e9eab , #eef2f3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      h2 {
				font-style:  italic;
				font-size: 2em;
				line-height: 1.6em;
				font-weight: bold;
				@media (max-width: 767px){
				font-size: 1.5em;
				}
			}
}
// cta ends
footer {
	padding: 50px 0px 20px;
	background: #1060ab; /* Old browsers */
	background: #000000; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #000000 , #434343); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #000000 , #434343); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
